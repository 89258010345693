import React from "react";
import styled from "styled-components";

const SecurityTxt = () => {
  return (
    <Wrapper>
      <p>Contact: mailto:rssi@smsmode.com</p>
      <p>Encryption: https://dev.smsmode.com/pgp.asc</p>
      <p>Preferred-Languages: en</p>
      <p>
        Acknowledgement: https://www.smsmode.com/politique-de-confidentialite/
      </p>
      <p>Canonical: https://dev.smsmode.com/.well-known/security.txt</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default SecurityTxt;
